* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;

}



@media (min-width: 12000px) {
  .container {
    max-width: 1120px;
  }
}

.row {
  margin-left: -$grid-gutter-width;
  margin-right: -$grid-gutter-width;

  &.no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
}

.row>* {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  margin: 0;
  overflow-x: hidden;
  font-family: $circular-book;
  font-size: $size16;
  line-height: 21px;
  background: none !important;
  
  &.active,
  &.overflow-hidden{
    overflow: hidden;
  }
  &.menu-active {
    overflow: hidden;
    left: 280px;
    position: relative;
    &:after {
      content: '';
      background: transparentize($black, .2);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

}

a {
  text-decoration: none;
  @include transition(0.3s ease all);
  display: inline-block;
  color: $theme-color;

  &:hover {
    text-decoration: none;
    color: $black1;
  }
}

img {
  max-width: 100%;
  transition:max-width 0.3s ease-out; // note that
}

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: $circular-bold;
   color: $black;
 }



h1 {
  font-size:  $size50 + $size50;
  line-height: 100px;
}

h2 {
  font-size:  31px;
  line-height: 43px;

  @media (max-width: 1439px) {
    font-size: 22px;
    line-height: 26px;
  }
}


h4 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 30px;
}

h6 {
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 0;
}

.form-control:focus {
  outline: none    !important;
  box-shadow: none !important;
  border-color:  #ced4da !important;
}

button:focus {
  outline: none    !important;
  box-shadow: none !important;
}



p {
  font-size: 15px;
  line-height: 21px;
  font-family: $circular-book;
  margin-bottom: 8px;
  strong {
    font-family: $circular-bold;
  }
  & + p {
    margin-top: 12px;
  }
}

body {
  .sidebar-wrapper {
    max-width: 280px;
    width: 280px;
    padding: 0;
    position: fixed;
    left: 0;
    height: 100%;
    top: 0;
    z-index: 10;
    .sidebar {

      &:after {
        content: '';
        background: rgba(0,0,0,0.06);
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
      .left-sidebar {
        background: $black1;
        max-width: 64px;
        width: 64px;
        height: 100vh;
        .logo-wrapper {
          text-align: center;
          padding: 30px 0 10px;
          border-bottom: 1px solid rgba(255,255,255,.17);
          a {
            span {
              font-size: 26px;
              color: #fff;
            }
          }
        }
        ul {
          padding: 0;
          list-style: none;
          text-align: center;
          li {
            a {
              color: $white;
              font-size: $size16;
              line-height: 22px;
            }
            & + li {
              margin-top: 8px;
            }
            &.active {
              a {
                color: $gray;
                text-decoration: underline;
              }
            }
          }
          &.upper-menu {
            margin-top: 20px;
            a {
              font-family: $circular-book;
            }
            @media (max-width: 991px) {
              margin-top: 80px;
            }
          }
          &.lower-menu {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            li {
              width: 43px;
              height: 43px;
              border-radius: 50%;
              position: relative;
              a {
                width: 100%;
                height: 100%;
                span.count-wrapper {
                  background: $red;
                  width: 19px;
                  height: 19px;
                  position: absolute;
                  border-radius: 50%;
                  font-size: 12px;
                  line-height: 12px;
                  top: -7px;
                  right: -8px;
                  text-align: center;
                  span.count {
                    position: relative;
                    top: 3px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        max-width: 216px;
        width: 216px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @media (max-width: 991px) {
          margin-top: 30px;
        }
        ul {
          padding: 0 30px;
          list-style: none;
          margin-top: 40px;
          li {
            position: relative;
            padding-left: 30px;
            margin-top: 20px !important;
            &:before {
              position: absolute;
              top:-1px;
              left: 0;
              font-family: 'icomoon';
            }
            a {
              font-size: 23px !important;
              line-height: 28px;
              color: $black;
              span {
                margin-right: 18px;
              }

              @media (max-width: 1680px) {
                font-size: 16px;
              }
            }
            & + li {
              margin-top: 14px;
            }
            //&.files-link {
            //  &:before {
            //    content:'\e901';
            //  }
            //}
            //&.users-link {
            //  &:before {
            //    content:'\e905';
            //  }
            //}
            //&.activity-link {
            //  &:before {
            //    content:'\e900';
            //  }
            //}
            //&.analytics-link {
            //  &:before {
            //    content:'\e903';
            //  }
            //}
            //&.billing-link {
            //  &:before {
            //    content:'\e90b';
            //  }
            //}
          }
        }
      }


    }
    @media (max-width: 991px) {
      left: -280px;
      width: 280px;
      height: 100%;
      &.active {
        left: 0;
        z-index: 9;
      }
    }



  }
  .content-area-wrapper {
    padding-bottom: 60px ;
    margin-top: 30px;
    @media (min-width: 992px) {
      margin-left: 280px;
      width: calc(100% - 280px);
    }
    @media (max-width: 991px) {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 70px;
    }
  }
}

/* sidebar responsive */

@media (max-width: 767px) and (orientation: landscape) {
  body .sidebar ul.upper-menu {
    margin-top: 40px;
  }
  body .sidebar ul.lower-menu {
    bottom: 20px;
  }
}




.form-wrapper {
  .form-group {
    margin-bottom: 0;
    label {
      font-size: 16px;
      margin-bottom: 4px;
      margin-top: 20px;
    }
  }
}

input:focus, .btn-theme:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bg-black {
  background: $black1;
  * {
    color: $white !important;
  }
  .btn-outline {
    &:hover {
      border-color: $white !important;
    }
  }
}



//margins
body {
  .mt-10 { margin-top: 10px}
  .mt-20 { margin-top: 20px}
  .mt-25 { margin-top: 25px}
  .mt-30 { margin-top: 30px}
  .mt-35 { margin-top: 35px}
  .mt-40 { margin-top: 40px}
  .mt-45 { margin-top: 45px}
  .mt-50 { margin-top: 50px}
  .mt-58 { margin-top: 58px}
  .mt-60 { margin-top: 60px}
  .mt-70 { margin-top: 70px}
  .mt-75 { margin-top: 75px}
  .mt-80 { margin-top: 80px}
  .mt-90 { margin-top: 90px}
  .mt-100 { margin-top: 100px}
  .mt-120 { margin-top: 120px}

  .marb-5 { margin-bottom: 5px !important;}
  .mb-10 { margin-bottom: 10px}
  .mb-20 { margin-bottom: 20px}
  .mb-25 { margin-bottom: 25px}
  .mb-30 { margin-bottom: 30px}
  .mb-45 { margin-bottom: 45px}
  .mb-40 { margin-bottom: 40px}
  .mb-50 { margin-bottom: 50px}
  .mb-58 { margin-bottom: 58px}
  .mb-60 { margin-bottom: 60px}
  .mb-70 { margin-bottom: 70px}
  .mb-75 { margin-bottom: 75px}
  .mb-80 { margin-bottom: 80px}
  .mb-90 { margin-bottom: 90px}
  .mb-100 { margin-bottom: 100px}

  .mt-24 {
    @media (min-width:768px) {
      margin-top: 24px;
    }
  }
}

.pr-60 {
  @media (min-width: 992px) {
    padding-right: 60px;
  }
}





.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;


}

.image-block {
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  img {
    opacity: 0;
    width: 100%;
  }
}


/* heading block */

.heading-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-left: 35px;
  padding-top: 22px;


  .title-block {
    display: block;
    width: max-content;
    flex-grow: 0 !important;
    margin-right: 30px;
    @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
      width: 100%;
    }
    h2 {
      margin-bottom: 0;
      width: max-content;
      font-size: 30px !important;
      .site-name {
        font-family: $circular-book;
        margin-left: 20px;
        @media (max-width: 1199px) {
          margin-left: 0;
          display: block;
        }
      }
      @media (max-width: 1439px) {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  #update-title-block {
    flex-grow: 1 !important;
  }


  .search-bar {
    width: 100%;
    padding: 0 40px;
    position: relative;
    margin-top: 3px;
    @media (max-width: 1439px) {
      padding: 0 20px;
    }
    @media (max-width: 767px) {
      padding: 0;
      margin: 10px 0 0;
    }
    span.icon-search {
      position: absolute;
      top: 50%;
      right: 60px;
      color: transparentize($gray, .40);
      transform: translateY(-50%);
      @media (max-width: 1439px) {
        right: 40px;
      }
      @media (max-width: 767px) {
        right: 20px;
      }
    }
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid transparentize($gray, .61);
      border-radius: 0;
      padding-right: 60px;
      background: none;
      &::placeholder {
        color: transparentize($gray, .61);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: transparentize($gray, .61);
      }

      &::-ms-input-placeholder {
        color: transparentize($gray, .61);
      }

    }
  }
  .button-wrapper {
    min-width: 162px;
    @media (max-width: 767px) {
      margin-top: 20px;
    }
    .btn-theme {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    display: block;
  }
}




.modal-backdrop {
  background-color: $black1;
  opacity: .89 !important;
  left: 64px;
  @media (max-width: 991px) {
    left: 0;
  }
}


  body .modal.show {
    display: flex !important;
    height: 100%;
  }

  body .modal.show .modal-dialog {
    margin: auto ;
    @media (max-width: 575px) {
      margin: auto 15px;
    }
  }




.modal {
    .modal-dialog {
      @media (min-width: 900px) {
        max-width: 840px;
        width: 100%;
      }
      .modal-content  {
        border-radius: 0;
      }
      button.close {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 16px;
      }
      .modal-body {
        overflow: hidden;
        @media (min-width: 768px) {
          padding: 40px 50px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          a {
            color: inherit;
            text-decoration: underline;
            &:hover {
              color: inherit;
            }
          }
        }
        form {

          label {
            font-family: $circular-bold;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 0;
          }
          input {
            @include border-radius(0);
            &::placeholder {
              color: transparentize($black, .6);
              font-size: 14px;
              opacity: 1;
            }

            &:-ms-input-placeholder {
              color: transparentize($black, .6);
              font-size: 14px;
            }

            &::-ms-input-placeholder {
              color: transparentize($black, .6);
              font-size: 14px;
            }
          }
          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: url("../../images/down.png") no-repeat 96% 50%;
          }
          small {
            display: block;
            color: $gray;
          }
          .wp-password-input-wrapper {
            display: flex;
            align-items: center;

            span[class*="icon-"] {
              display: inline-block;
              padding-left: 10px;
            }
          }
          select {
          }
          .button-wrapper {
            margin-top: 40px;
            .btn-theme {
              min-width: 160px;
            }
          }
        }
        .btn-theme {
          margin-bottom: 10px;
        }
        h4.date-time {
          padding-left: 20px;
          margin-top: 5px;
        }
        ul {
          li {
            font-size: 14px;
            line-height: 20px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          border-style: solid;
          border-width: 58px 50px 0 0;
          border-color: transparent;
        }
      }
    }
  &.restore-backup-live {
    .modal-body {
      &:before {
        border-color: $green1 transparent transparent transparent;
      }
    }
    h4.date-time {
      color: $green1;
    }
  }
  &.restore-backup-staging {
    .modal-body {
      &:before {
        border-color: $yellow1 transparent transparent transparent;
      }
    }
    h4.date-time {
      color: $yellow1;
    }
  }
}


/* custom checkbox */

input[type="checkbox"] {
  //display: none;
  & + label {
    font-family: $circular-book !important;
    margin-top: 3px;
    &:before {
      position: absolute;
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 0;
      left: 0;
      top: 4px;
      display: block;
      background-color: transparent;
      border: 1px solid $black;
    }
    &:after {
      position: absolute;
      content: "";
      border-radius: 0;
      background: 0 0;
      transition: all .4s ease-out;
      width: 8px;
      height: 8px;
      left: 3px;
      top: 7px;
    }
  }
  &:checked {
    & + label {
      font-family: $circular-bold !important;
      &:after {
        background: $black;
      }
    }
  }
}


/* message styling */

.message {
  position: fixed;
  top: 0;
  left: 280px;
  right: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $white;
  padding: 5px 15px;
  z-index: 1051;

  &.add-domain-success {
    left: 64px;
    @media (max-width: 991px) {
      left: 0;
    }
  }
  &.message-success {
    background: $purple;
  }

  &.message-danger {
    background: $red1;
  }

  &.message-normal {
    background: $yellow;
  }
  @media (max-width: 991px) {
    left: 0;
  }
}

/* dropdown menu */

.dropdown-menu {
  @include border-radius(0);
  border:none;
  box-shadow: 0 3px 6px transparentize($black, .57);
  padding: 0;
  a {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 8px 24px;
    & + a  {
      border-top: 1px solid transparentize($black, .94);
    }
    &:active, &:focus {
      background: transparent;
      outline: none !important;
      color: $black;
    }
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 2px;
    left: -webkit-calc(50% + 30px);
    left: calc(50% + 30px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    @include rotate(135);
    @include transform-origin(0 0);
    box-shadow: -2px 2px 0 -1px rgba(0,0,0,.15);
  }
}


//revel animation

.revel-effect {
  overflow: hidden;
  position: relative;

  > * {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    position: relative;
  }
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 0);
    transform: scale(0.05, 0);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    @include transition(0.3s ease all);
  }


}

.moveImage {
  -webkit-animation: moveImageFwd 1s infinite  alternate;
  animation: moveImageFwd 1s infinite  alternate;

  &.rev {
    -webkit-animation: moveImageRev 1s infinite  alternate;
    animation: moveImageRev 1s infinite  alternate;
  }
}

//image svg animation
@-webkit-keyframes moveImageFwd {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes moveImageFwd {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@-webkit-keyframes moveImageRev {
  0% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}
@keyframes moveImageRev {
  0% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

//.has-error{
//  color : red !important;
//  margin-top: -5px;
//  margin-bottom: 20px;
//}
.heading-block {
  align-items: flex-start;
}



@media (max-width: 1199px) {
  .heading-block .title-block {
    margin-bottom: 10px;
  }
}

