//Font Family
@font-face {
  font-family: 'Circular-Bold';
  src: url('../../fonts/CircularStd-Bold.eot');
  src: url('../../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Bold.woff2') format('woff2'),
  url('../../fonts/CircularStd-Bold.woff') format('woff'),
  url('../../fonts/CircularStd-Bold.ttf') format('truetype'),
  url('../../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Book';
  src: url('../../fonts/CircularStd-Book.eot');
  src: url('../../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Book.woff2') format('woff2'),
  url('../../fonts/CircularStd-Book.woff') format('woff'),
  url('../../fonts/CircularStd-Book.ttf') format('truetype'),
  url('../../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url('../../fonts/CircularStd-Medium.eot');
  src: url('../../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Medium.woff2') format('woff2'),
  url('../../fonts/CircularStd-Medium.woff') format('woff'),
  url('../../fonts/CircularStd-Medium.ttf') format('truetype'),
  url('../../fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Courier-New-Regular';
  src: url('../../fonts/CourierNewPSMT.eot');
  src: url('../../fonts/CourierNewPSMT.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CourierNewPSMT.woff2') format('woff2'),
  url('../../fonts/CourierNewPSMT.woff') format('woff'),
  url('../../fonts/CourierNewPSMT.ttf') format('truetype'),
  url('../../fonts/CourierNewPSMT.svg#CourierNewPSMT') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot');
  src:  url('../../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf') format('truetype'),
  url('../../fonts/icomoon.woff') format('woff'),
  url('../../fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  color: #0d21e3;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  color: #0d21e3;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-hdl:before {
  content: "\e100";
}
.icon-share:before {
  content: "\e101";
}
.icon-billing:before {
  content: "\e102";
}
.icon-logout:before {
  content: "\e103";
  color: #000000 !important;
}
.icon-check:before {
  content: "\e104";
  color: #0d21e3 !important;
}
.icon-minus:before {
  content: "\e105";
  color: #0d21e3 !important;
  font-size: 20px;
}
.icon-add:before {
  content: "\e106";
}
.icon-bar_diagram:before {
  content: "\e107";
}
.icon-close:before {
  content: "\e108";
}
.icon-compare:before {
  content: "\e109";
}
.icon-menu:before {
  content: "\e10a";
}
.icon-down:before {
  content: "\e10b";
}
.icon-long-arrow:before {
  content: "\e10c";
}
.icon-more:before {
  content: "\e10d";
}
.icon-next-to:before {
  content: "\e10e";
}
.icon-next:before {
  content: "\e10f";
}
.icon-organization:before {
  content: "\e110";
}
.icon-pie_chart:before {
  content: "\e111";
}
.icon-previous-to:before {
  content: "\e112";
}
.icon-previous:before {
  content: "\e113";
}
.icon-reset:before {
  content: "\e114";
}
.icon-roles:before {
  content: "\e115";
}
.icon-search:before {
  content: "\e116";
}
.icon-data_center:before {
  content: "\e117";
}
.icon-browser:before {
  content: "\e118";
}
.icon-up:before {
  content: "\e119";
}
.icon-user:before {
  content: "\e11a";
}
.icon-discount:before {
  content: "\e901";
}
.icon-payment:before {
  content: "\e900";
}