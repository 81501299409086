/* settings block */

.single-setting {
  .setting-title {
    h2 {
      color: $white;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0;
      background: $darkblue;
      padding: 23px 50px 23px 35px;
      position: relative;
      border-right: 30px solid transparent;
      
      &.live {
        border-color: $green2;
      }

      &.staging {
        border-color: $yellow2;
      }
      @media (max-width: 1680px) {
        padding: 18px 35px;
      }
      @media (max-width: 991px) {
        padding: 15px 45px 15px 15px;
        font-size: 16px;
        line-height: 22px;
      }

      span.status {
        float: right;
        text-transform: uppercase;
        font-family: $circular-book;
      }
      //&.staging {
      //  &:after {
      //    position: absolute;
      //    content: '';
      //    background: $yellow;
      //    width: 30px;
      //    top:0;
      //    right: 0;
      //    height: 100%;
      //  }
      //}
    }
  }
  .setting-content {
    .setting-content-wrapper {
      padding: 30px 0 35px;
      .padded-block {
        // padding: 0 35px;
        @media (max-width: 991px) {
          padding: 0 15px;
        }
      }
      .content-label {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 12px;

        @media (max-width: 1680px) {
          font-size: 14px;
        }
      }
      .content-info {
        font-size: 12px;
        line-height: 14px;
        background: rgba(248,248,248,.75);
        padding: 11px 15px;
        margin-bottom: 30px;
        font-family: $courier;
      }
      .load-more-wrapper {
        max-width: 430px;
        margin: 40px auto 0;
        padding: 0 15px;
        .btn-theme {
          width: 100%;
        }
      }
      .inline-buttons {
        @media (max-width: 767px) {
          a {
            display: inline-block;
            margin: 0 10px 12px 0;
            width: auto;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .tab-content {
        .setting-table {
          border-top: none;
        }
      }
      hr {
        border-top: 1px solid rgba(0,0,0,.06);
      }
    }
  }
  & + .single-setting {
    margin-top: 20px;
  }
}

.table {
    thead {
      tr {
        th {
          font-size: 14px;
          line-height: 21px;
          font-family: $circular-bold;
          font-weight: normal;
          padding: 5px 15px;
          position: relative;
          border: none;
          vertical-align: middle;
          cursor: pointer;
          &:first-child {
            @media (min-width: 992px) {
              padding-left: 35px;
            }
          }
        }
        th:last-child {
          cursor: default;
        }
      }
    }
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: white !important;
        }
        &:nth-of-type(even) {
          background-color: #fcfcfd !important;
        }
        td {
          font-size: 14px;
          line-height: 16px;
          font-family: $circular-book;
          padding: 17px 15px;
          border: none;
          vertical-align: middle;
          &:first-child {
            @media (min-width: 992px) {
              padding-left: 35px;
            }
          }
          .icon-more {
            color: blue;
          }
          &.buttons-block {
            // width: 200px;
            // min-width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
              margin-right: 30px;
            }
            span.icon-minus {
              margin-left: 5px;
              color: $red;
              position: relative;
              top: 1px;
            }
          }
        }

        .domain-name-td {
          width: 20%;
        }
        .table-view-block{
          width:200px;
          text-align: right;
        }
      }
    }
  .btn-theme {
    min-width: 125px;
  }
  }


.table-block {
  .table {
    #backup-table-head {
      border-bottom: 1px solid black !important;
    }
    thead {
      tr {
        border-bottom: 2px solid #1A293B;
        th {
          font-family: $circular-book;
        }
        th:last-child {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 25px 15px;
          &:first-child {
            font-family: $circular-bold;
            @media (min-width: 992px) {
              padding-left: 35px;
            }
          }

          // #more-options {
          //   margin-bottom: 6px;
          // }
        }

        .last-item {
          display: flex;
          justify-content: center;
        }

        .status, .available, .activity-status, .paid {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 135px;
          position: relative;

          p {
            margin: 0 !important;
            padding: 0 !important;
          }

          img {
            position: absolute;
            right: 0;
            top: -6px;
          }
        }

        .status {
          width: 130px;

        }

        .available {
          width: 145px;

        }

        .paid {
          width: 125px;

        }
      }
    }
  }

  #resources-table {
    
    th:first-child {
      width: 40%;
    }
  }
}

.setting-table {
  border-top: 1px solid transparentize($black, .94);
  padding-top: 20px;
}

#domain-table {

  .table {

    tbody {

      .primary-name {
        
        .site-name {
          font-size: 10px;
          color: gray;
        }

        p {
          margin: 0;
        }
      }

      .ip-block {
        width: 35%;
        .primary-ip {
          margin-left: 4rem;
          position: relative;
          // border-right: solid 2px transparentize($black, .94);
          
          .site-ip {
            font-size: 10px;
            color: gray;
          }
  
          p {
            margin: 0;
            color: gray;
          }
        }

        #primary-ip {
          border-right: none;
        }
      }

      #domain-ip {
        position: relative;
        button {
          position: relative;
        }
        button:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 63px;
          background: lightgray;
          top: 50%;
          transform: translateY(-50%);
          left: -60px;
        }
      }
    }

    @media (max-width: 1350px) {
      tr {
        display: block;
        align-items: center;

        .domain-name-td {
          width: 50%;
        }

        .buttons-block {
          justify-content: flex-start !important;
        }
      }
    }
  }
}

.nav-pills {
  padding-left: 20px;
  margin-bottom: 30px;
  // padding-bottom: 40px;
  // border-bottom: 1px solid transparentize($black, .94);
  @media (max-width: 991px) {
    padding-left: 0;
  }
  li.nav-item {
    margin-right: 20px;
    a.nav-link {
      color: $black;
      padding: 10px 30px;
      &.active {
        // border-radius: 57px;
        background-color: #0d21e3 !important;
        color: white;
        cursor: pointer;
        &:after {
          // content: '';
          // background: $black;
          // height: 1px;
          // position: absolute;
          // bottom: 0;
          // width: calc(100% - 30px);
          // left: 15px;
          // right: 15px;
        }
      }
    }
    #pills-daily-tab.active, 
    #pills-manual-tab.active, 
    #pills-error-tab.active, 
    #pills-access-tab.active {
        border-radius: 50px;
    }
  }
  .company-or-individual {
    display: flex;
    align-items: center;
    color: red;
  }
}

.nav-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .nav-content-header-refresh {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
    }

    img {
      width: 15px;
      margin-left: 20px;
    }
  }
}

.tab-info-wrapper {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
    .tab-info-block {
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  .tab-backup-button .tab-domain-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (max-width: 1200px) {
      align-items: flex-end;
      margin-top: 30px;
    }
    .btn-theme {
      width: 230px;
      font-family: $circular-bold;
    }
  }
}

/* my account block*/
.myaccount-block {
  margin-top: 30px;
  padding: 0 15px;
  @media (min-width: 992px) {
    // padding: 0 60px;
    padding: 0 15px;
  }
  .myaccount-avatar {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .account-gravatar {
      border-radius: 60%;
      padding-left: 15px;
      margin-bottom: 10px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
#myaccount-feature {
  width: 100%;

  .single-feature {
    padding-bottom: 25px;
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    .feature-information {
      width: 50%;

      p {
        font-size: 14px;
      }

      .feature-name-section {
        margin: 0;

        a {
          text-decoration: underline;
        }
      }
    }

    #ssl-information {
      width: 70%;
    }
  }
}


/* features block */

.features-block {
  margin-top: 30px;
  padding: 0 15px;
  @media (min-width: 992px) {
    // padding: 0 60px;
    padding: 0 15px;
  }
}

.feature-col {
  // margin-bottom: 40px;
}

.single-feature {
  // text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  .feature-information {
    display: flex;
    width: 300px;

    //.feature-profile-image-section{
    //  margin-left: 20px;
    //
    //}

    .feature-name-section {
      margin-left: 20px;
      .profile-avatar {
        border-radius: 60%;
        height: 40px;
        width: 50px;
      }
      .site-tools-heading, h3 {
        margin: 0;
      }
    }


    .site-tools-heading {
      font-size: 10px !important;
      color: gray;
    }
  }

  img.feature-img {
    // margin-top: 30px;
    width: 39px;
    // width: 2rem;
  }
  h3 {
    font-size: 17px;
    line-height: 27px;
    // margin: 10px 0 18px;
  }
  // p {
  //   font-size: 16px;
  //   line-height: 20px;
  //   max-width: 272px;
  //   // margin: 0 auto 28px;
  // }
  .site-tools-description {
    font-size: 12px !important;
    line-height: 14px !important;
    width: 30%;
    // margin: 0 auto 28px;
  }
  .btn-wrapper {
    // position: absolute;
    // bottom:0;
    // left: 50%;
    // transform: translateX(-50%);
    .btn-theme {
      min-width: 153px;
      font-size: 14px;
      font-family: $circular-bold;
    }
  }
}

.custom-profile-update{
  flex: 0 0 100%;
  max-width:100%;
  margin-top:30px;
  .single-feature {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex:0 0 100% !important;
    border-bottom: none !important;

    .feature-information {
      width: auto !important;

      .feature-avatar-section {
        width:300px;
        text-align:center;
        margin-left: 20px;
        .profile-avatar {
          /*border-radius: 60%;
          height: 40px;
          width: 50px;*/
          display:block;
          margin:0 auto;
          width:auto;
          height:auto;
          img{
            width:200px;
            border-radius:50%;
            height:200px
          }
        }
        .site-tools-heading, h3 {
          margin: 0;
        }
      }


      .site-tools-heading {
        font-size: 10px !important;
        color: gray;
      }
    }

    .box-block{
      max-width:none;
      width:100%
    }
  }
}

#cache-row {

  .file-input {
    width: 50%;
    
    h4 {
      font-size: 15px;
      margin-bottom: 5px;
    }

    // div {
    //   width: 100%;
    //   height: 220px;
    //   border-radius: 2px;
    //   border: solid 1px #e0e0e0;
    // }
  }

  #cache-col {
    width: 47%;
    
    #cache-feature {
      border-bottom: none;
    }
  }
}

@media (max-width: 1200px) {
  #cache-row {
   flex-direction: column;

    #cache-col {
      width: 100%;
    }
    #cache-col:last-child {
      margin-top: 20px;
    }

    .file-input {
      width: 100%;
      margin: 30px 0 0 0 !important;
    }

    #ssl-information {
      width: 100% !important;
    }
  }
}


#ignore-cache-feature {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06) !important;

  .single-feature {
    align-items: flex-end;
    border-bottom: none;

    .feature-information {
      width: 50%;

      p {
        font-size: 14px;
      }

      .feature-name-section {
        margin: 0;

        a {
          text-decoration: underline;
        }
      }
    }

    #ssl-information {
      width: 70%;
    }
  }
}

#redirects-feature {
  width: 100%;
  //border-top: 1px solid rgba(0, 0, 0, 0.06) !important;

  .single-feature {
    align-items: flex-end;
    border-bottom: none;

    .feature-information {
      width: 50%;

      p {
        font-size: 14px;
      }

      .feature-name-section {
        margin: 0;

        a {
          text-decoration: underline;
        }
      }
    }

    #ssl-information {
      width: 70%;
    }
  }
}

#first-feature {
  padding-top: 0;
}

/* create remove staging block */

.create-remove-block {
  display: flex;
  flex-wrap: wrap;
  .single-block {
    text-align: center;
    padding: 50px 15px;
    flex: 0 0 calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    //transition: .2s all ease;
    h2 {
      font-size: 19px;
      line-height: 27px;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      max-width: 272px;
      margin: 0 auto;
    }
    .button-wrapper {
      margin-top: 24px;
    }
    .btn-theme {
      max-width: 400px;
      width: 100%;
      height: 33px;
      font-size: 11px;
      line-height: 30px;
    }
    &.danger-block {
      border: 1px solid #D8D8D8;
      position: relative;
      flex: 0 0 54px;
      .danger-block-wrapper {
        .vertical-bar {
          background: #585858;
          position: absolute;
          left: 0px;
          top: 0;
          width: 54px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          cursor: pointer;
          h2 {
            transform: rotate(90deg);
            margin: 0;
            white-space: nowrap;
            color: $white;
            text-transform: uppercase;
          }
          span {
            font-size: 25px;
            color: $white;
            display: none;
          }
        }
        .danger-block-content {
          display: none;
          padding-left: 54px;
        }
      }
    }
  }
  &.full-block {
    .single-block {
      flex: 0 0 50% !important;
      @media (max-width: 575px) {
        flex: 0 0 100% !important;
      }
      &.danger-block {
        .danger-block-wrapper {
          .vertical-bar {
            background: $red1;
            h2 {
              display: none;
            }
            span {
              display: block;
            }
          }
          .danger-block-content {
            display: block;
          }
        }
      }
    }
  }
}


/* portfolio page */

.portfolio {
  background: $black1;
  .right-sidebar * {
    display: none;
  }
  .sidebar-wrapper .sidebar:after {
    display: none;
  }
  .content-area-wrapper {
    color: $white;
    @media (max-width: 991px) {
      padding: 0 15px;
    }
    .heading-block {
      margin-bottom: 25px;
      padding-left: 0;
      @media (max-width: 767px) {
        display: flex;
        align-items: center;
      }
      .title-block {
        width: auto;
        h2 {
          color: $white;
        }
      }
      .total-sites {
        p {
          font-family: $circular-bold;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 0;
          @media (max-width: 1439px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.single-portfolio {
  border-radius: 5px;
  background: #2B3B4D;
  padding: 22px;
  margin-bottom: 30px;
  .link-block {
    margin-top: 12px;
    a {
      color: $white;
      font-family: $circular-bold;
      font-size: 11px;
      line-height: 15px;
      &:hover {
        color: inherit;
      }
    }
  }
}

/* messgae page */

.message-page {
  header {
    display: none;
  }
}

.message-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 580px;
  padding: 0 15px;
  text-align: center;
  width: 100%;
  @media (max-width: 812px) and (orientation: landscape) {
    top: 65%;
    padding-bottom: 30px;
  }
  .heading-img {
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    img {
      &.asset1 {
        max-width: 180px;
      }
      &.asset2 {
        max-width: 380px;
        @media (max-width: 767px) {
          max-width: 250px;
        }
      }
    }
  }
  h2 {
    font-size: 50px;
    line-height: 70px;
    font-family: $circular-medium;
    @media (max-width: 991px) {
      font-size: 29px;
      line-height: 41px;
    }
  }
  p {
    font-size: 23px;
    line-height: 33px;
    margin-bottom: 0;
    & + p {
      margin-top: 20px;
      @media (max-width: 991px) {
        margin-top: 10px;
      }
    }
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}


/* login pages */

.login-pages {
  header {
    display: none;
  }
}

.dashboard-login {
  .logo-block {
    background: #0e1127;
  }
}

.hosting-login {
  .logo-block {
    background: $blue;
  }
}

.logo-block {
  position: relative;
  padding: 50px 0;
  @media (min-width: 992px) {
    height: 100%;
    padding: 0;
    min-height: 100vh;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 30px;
    width: 400px;
  }
}

.form-block {
  padding: 30px 10%;
  @media (max-width: 991px) {
    padding: 30px;
  }
  h2 {
    text-align: center;
  }

  #huston {
    font-size: 32px;
    text-align: center;
  }

  #activate {
    font-size: 32px;
    text-align: center;
  }

  #activate-detail {
    text-align: center;
  }

  form {
    margin-top: 50px;
    @media (max-width: 991px) {
      margin-top: 30px;
    }

    #login-input {

      label {
        margin-top: 10px;
      }
      
      input {
        border: none;
        border-bottom: solid 2px #d1d1d1;
        border-radius: 0;
        margin-top: 10px;
        background: none;

      }

      input:focus {
        border-bottom: solid 2px #0d21e3 !important;
      }
    }

  }

  label {
    font-size: 18px;
    margin-bottom: 0;
    color: #383838;
  }

  input {
    border-radius: 3px;
    border: 1px solid transparentize($gray, .82);
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    &::placeholder {
      color: transparentize(#383838, .86);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: transparentize(#383838, .86);
    }

    &::-ms-input-placeholder {
      color: transparentize(#383838, .86);
    }
  }






  .button-wrapper {
    margin-top: 50px;
    .btn-theme {
      min-width: 150px;
      @media (max-width: 767px) {
        margin-right: 0;
        display: block;
        margin: 0 auto 12px;
        max-width: 170px;
        min-height: auto;
      }
    }


  }
}

/* welcome block */

.welcome-block {
  max-width: 570px;
  margin: 0 auto;
  padding: 8% 15px;
  text-align: center;
  .btn-wrapper {
    margin-top: 30px;
  }
}

/* package block */

.packages-block {
  text-align: center;
  .package-heading-block {
    padding: 0 15px;
  }
  small {
    & + h2 {
      margin-top: 10px;
    }
  }
  h2 {
    font-size: 22px;
    line-height: 26px;
    @media (min-width: 1440px) {
      font-size: 42px;
      line-height: 44px;
    }
  }
  .packages-slider {
    margin-left: 5%;
    overflow-x: hidden;
    padding: 50px 0;
    @media (max-width: 991px) {
      margin: 0;
    }
    .slick-list {
      overflow: visible;
    }

  }
  .packages-info-block {
    max-width: 630px;
    margin: 40px auto 0;
    padding: 0 15px;
  }
  .single-package {
    border: 1px solid #f4f4f4;
    padding: 60px 15px 30px;
    &.premium-package {
      transform: scale(1.2);
      background: $blue;
      border: none;
      border-radius: 4px;
      * {
        color: $white !important;
      }
    }
    &:focus {
      outline: none;
    }
    &:not(.slick-active) {
      @media (min-width: 576px) {
        opacity: .13;
      }
    }
    h2 {
      font-family: $circular-book;
      strong {
        font-family: $circular-bold;
      }
    }
    p {
      @media (min-width: 1440px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    ul {
      margin-bottom: 28px;
      li {
        line-height: 18px;
        @media (min-width: 1440px) {
          font-size: 18px;
          line-height: 20px;
        }
        & + li {
          margin-top: 4px;
        }
      }
    }
    & + .single-package {
      border-left: none;
    }
  }
}


/* billing block */

.billing-block {
  max-width: 450px;
  padding: 0 15px;
  margin: 60px auto;
  .billing-info-block {
    text-align: center;
    margin-bottom: 60px;
    .billing-price {
      padding: 20px 0;
        h2 {
          font-family: $circular-book;
          @media (min-width: 1440px) {
            font-size: 53px;
            line-height: 56px;
          }
          strong {
            font-family: $circular-bold;
          }
        }
      p {
        @media (min-width: 1439px) {
          font-size: 23px;
          line-height: 26px;
        }
      }
    }
    .billing-features {
      position: relative;
      ul {
        margin: 0;
        padding: 20px 0;
        li {
          font-size: 18px;
          line-height: 20px;
          @media (max-width: 374px) {
            font-size: 15px;
            line-height: 18px;
          }
          & + li {
            margin-top: 6px;
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: $gray;
        top: 0;
        left: -15px;
      }
    }
  }

  .payment-block {
    small {
      color: #666;
    }
    ul.nav-pills {
      padding-left: 0;
      border-bottom: 2px solid rgba(0,0,0,.06);
      li.nav-item {
        a.nav-link {
          padding: 10px 5px;
          svg {
            height: 28px;
            .cls-1 {
              fill:#f2f2f2;
            }
            .cls-2 {
              fill:#b3b3b3;
            }
          }
          &.active {
            top: 0;
            &:after {
              width: 100%;
              left: 0;
              right: 0;
              height: 2px;
              bottom: -1px;
            }
            svg {
              height: 28px;
              .cls-1 {
                fill: $black1;
              }
              .cls-2 {
                fill:$white;
              }
            }
          }
        }
      }
    }
    .tab-content {
      .payment-details {
        margin-top: 30px;
        box-shadow: 0 20px 50px transparentize($black, .9);
        padding: 30px;
        border-radius: 12px;
        @media (max-width: 374px) {
          padding: 15px;
        }
        p {
          font-size: 12px;
          line-height: 16px;
          font-family: $circular-bold;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        .form-group {
          position: relative;
          margin-bottom: 0;
          margin-top: 20px;
          .floating-label {
            font-size: 12px;
            line-height: 16px;
            color: $gray1;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: .3s all ease;
            pointer-events: none;
          }
          .form-control {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #E6E6E6;
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
            z-index: 9;
            &:focus, &:not(:focus):valid  {
              & ~ .floating-label {
                top: 0;
              }
            }
          }
        }
        button[type="submit"] {
          font-family: $circular-bold;
        }
      }
    }
  }
}

.form-block{
  input[type="checkbox"]{
    display: block;
    height:22px;
    position:relative;
    top:-20px;
    right: 45px;

  }
}

.domain-form{
  input[type="checkbox"]{
    display: block;
    height: 22px;
    position: relative;
    top: -25px;
    left: 0px;
  }
}

 .bug-form{
  input[type="checkbox"]{
    display: block;
    height: 22px;
    position: relative;
    top: -25px;
    left: 0px;
  }
  .input {
    width: 100%;
    border: none;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border-bottom: 2px solid #d4d5d6;
    border-radius: 0;
    color: #565656;
    -webkit-appearance: none;
  }

  .input:focus {
    //border-color: cornflowerblue;
    outline: none;
  }

  .input.has-error {
    border-color: tomato;
  }

  .error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }

  .tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
  }

  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.roles-permissions-list{
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 10px;
  margin-right: 10px;
  //background: #eee;
  padding: 20px;
  label.list-title {
    font-weight: bold;
    letter-spacing: .5px;
    margin-bottom: 4px;
    padding-bottom: 4px;
    //border-bottom: 1px solid #f6f6f6;
    display: block;
  }
  input[type="checkbox"]{
    display: block;
    height:22px;
    position:absolute;
    top:0;
    right: 110px;
  }
}

input[type="checkbox"]{
  display: block !important;
  //height:22px;
  //position:absolute;
  //top:0;
  //right: 110px;
}


html.menu-active {
  overflow: hidden;
}
body.menu-active {
  left: auto;
  position: static;
}
body.menu-active #root {
  left: 280px;
  position: relative;
}
body.menu-active:after {
  left: 280px;
  width: calc(100% - 280px);
}


//new css
.badge {
  font-size: 12px !important;
  min-width: 100px;
  padding: 7px 10px !important;
}

h2[data-toggle="collapse"] {
  cursor: pointer;
}

.content-info-wrapper {
  position: relative;

  .action-btn-copy {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin: 0 !important;

    button {
      border: none;
      background: rgba(248,248,248,1);
      height: 30px;
      line-height: 30px;
      width: 40px;
      border-radius: 0;
      border-left: 0.046875rem solid transparentize($darkblue, 0.9);
      color: $darkblue;
    }
  }
}

.single-setting {
  .setting-title {
    h2 span {
      font-size: 18px;
    }
  }
  .setting-content {
    .setting-content-wrapper {
      .content-info {
        &.content-info-wrapper {
          padding: 20px 40px 13px 15px;
          font-size: 16px;

          @media (max-width: 1680px) {
            font-size: 14px;
          }

          .admin-url {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }
      }
    }
  }
}

//checkbox with label
.checkbox-wrapper {
  label {
    padding-left: 25px;
    margin-bottom: 4px;
  }
}

//button space
.button-wrapper {
  button {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

//form validation error
.has-error {
  select {
    border: 1px solid red;
  }
  input, select, textarea {
    border-bottom: 1px solid red !important;
  }
}

//nav-pills
.nav-pills {
  li {
    a {
      cursor: pointer;
    }
  }
}

//table-list-block
.table-list-block {
  overflow: visible !important;
 #backup-table-head {
   border-bottom: 1px solid black;
 }
  thead {    
    tr {
      th {
        &:first-child {
          // width: 300px;
        }
        
        &:last-child {
          text-align: center;
        }
      }
    }
  }
}

//sidebar menu
.right-sidebar {

  .sidebar-content {
    height: 88%;

    .title-block {
      display: flex;
      align-items: center;
      background-color: #0d21e3;
      color: white;

      p {
        font-size: 13px !important;
        margin-left: 20px;
      }

       a {
        margin-top: 3px;
        // font-size: 23px !important;
        // line-height: 28px !important;
        
         span {
           font-size: 20px !important;
           padding-top: 2px !important;
           color: white !important;
         }

         img {
           width: 98%;
         }
       }
    }
  }
  .main-menu,
  .right-menu {
    li {
      padding-left: 0 !important;
      a {
        i {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 16px;
        }
      }

      + li {
        margin-top: 10px !important;
      }
    }
  }

  .main-menu {
    margin-top: 90px !important;

    li {
      a {
        font-size: 20px !important;
      }

      .active {
        font-weight: bold;
        position: relative;
        // text-decoration: underline;
      }
    }

    #activity-log {
      margin-top: 50px !important;
    }

    #pending {
      margin-bottom: 50px;
    }

    #coupon {
      margin-bottom: 100px;
    }
  }

  .right-menu {
    ul {
      li {
        a {
          //font-size: 16px !important;
        }
      }
    }
  }

  .lower-menu {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .avatar {
      margin-right: 12px;
    }

    #username {
      font-size: 15px !important;
    }

    #logout {
      margin-bottom: 4px;
    }

    li {
      padding-left: 0 !important;
      margin-top: 0 !important;
      font-size: 14px;
      display: flex;
      align-items: center;

      a {
        i {
          font-size: 12px;
        }
      }

      + li {
        margin-left: 47px !important;
      }
    }
  }
}

//page title block
.heading-block {

  #site-dashboard-heading {
    margin-left: 35px;
    
    .display-name {
      margin-top: 15px;
      margin-bottom: 8px;
      h2 {
        font-size: 42px !important;
      }
    }
  }

  @media (max-width: 1200px) {
    #site-dashboard-heading {
      margin-left: 0;
    }

    #add-site {
      margin-bottom: 15px;
    }
  }

  .title-block {
    h2 {
      margin-bottom: 5px;
    }

    span {
      font-size: 12px;
    }
  }
}

//form
.box-block {
  .form-group {
    margin-bottom: 15px;
  }

  .update {

    input {
      background-color: #f7f7f7;
      color: #c9c9c9;
      border: none;
      border-radius: 3px;
      cursor: not-allowed;
    }

    select {
      background-color: #f7f7f7;
      color: #c9c9c9;
      border: none;
      border-radius: 3px;
      cursor: not-allowed;
      background-image: none;
    }
    
    #public_hardware_address {
      &::placeholder {
        color: #c9c9c9 !important;
      }
    }
  }
}

.form-row {
  + .button-wrapper {
    margin-top: 20px;
  }
}
#form-submit-btn {
  text-align: end !important;
}

//swal-modal

.swal-overlay  {
  .swal-modal {
    padding: 30px;

    .swal-text {
      margin-top: 0;
    }

    .swal-footer {
      padding: 0;
      margin-top: 20px;
      text-align: center;

      .swal-button-container {
        margin: 0 15px 0 0;

        .swal-button--cancel {
          background: $dark-light-gray;
        }

        .swal-button--catch {
          background: $red1;
        }

        button {
          opacity: 1;
          @include transition(0.3s ease all);
          &:hover {
            opacity: 0.9;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

//share icon
a {
  &.open-admin {
    .icon-share {
      color: #999;
      font-weight: bold;
    }
  }
}

//switch
.react-switch-bg {

}

.single-setting .setting-content .setting-content-wrapper .content-info {
  line-height: 20px;
  font-size: 16px;
  padding: 17px 40px 10px 15px;
}



@media (max-width: 1680px) {
  .single-setting .setting-content .setting-content-wrapper .content-info {
    font-size: 14px;
  }
}



.single-setting .setting-content .setting-content-wrapper .content-info.content-info-wrapper {
  padding: 17px 40px 10px 15px;
}



.single-setting .setting-content .setting-content-wrapper p {
  font-size: 16px;
  line-height: 22px;
}



.single-feature h3 {
  line-height: 23px;
}


  //#domain .table thead tr th {
  //  font-size: 16px;
  //}


.table thead tr th, .table tbody tr td {
  font-size: 15px;
}

//.table-block .table tbody tr td {
//  .onclick{
//  cursor: pointer;
//    }
//}

  .onclick{
  cursor: pointer;
    }

@media (min-width: 1681px) {
  #domain .table thead tr th {
    font-size: 16px;
  }
}

.single-setting .setting-content#domain .setting-content-wrapper .content-info.content-info-wrapper {
  padding: 17px 50px 10px 15px;
}

.password-reset
{
  transform: translate(0rem, 0.3rem);
}

#reset {
  width: 19px;
  margin-bottom: 0.75em;
}

.password-copy
{
  transform: translate(-5rem, 0.3rem);
}

.box-block .wp-password-input-wrapper a.password-copy {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}


.lower-menu{
  //li{
  //  position: fixed;
  //}
  .avatar{
    //padding-top: 20px;
  }
  .sidebar-avatar{
    border-radius: 60%;
    height:40px;
    width: 50px;
    //padding-top: 20px;
  }
  img {
    width: 39px;
    height: 39px;
    border-radius: 50%;
  }
}

.top-navigation {
  justify-content: center;
  color: white;

   ul {
     display: flex;
     list-style: none;
     align-items: center;
     margin-bottom: 0 !important;
     padding: 0;

     li {
       margin: 0 60px;

       a {
        color: white;
       }

       .active {
         font-weight: bold;
       }
     }
   }
}

.org-menu {
  height: 100%;
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 2px solid rgba(0, 0, 0, 0.027);
  padding-top: 90px;

  ul {
    list-style: none;
    padding: 0 0 0 25px;

    li {
      margin-bottom: 10px;
    }

    #payments-link {
      margin-top: 50px;
    }

    #disabled-org-link {
      pointer-events: none;
      cursor:not-allowed;
    }

    .active {
      position: relative;
      font-weight: bold;
    }

    .active::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 2px;
      background: #0d21e3;
      top: 50%;
      transform: translateY(-50%);
      left: -27px;
    }
  }
}

.under-development {
  flex-direction: column;  
  text-align: center;

  h3 {
    font-size: 35px;
    margin: 0;
    
    span {
      color: #ffa700;
    }
  }

  .spacing {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  .btn-spacing {
    margin-bottom: 40px;
  }
}

.settings-block {
  padding: 0 60px 0 35px;
  .site-dashboard-links {
    display: flex;
    list-style: none;
    border-top: solid 0.3px #d8d8d8;

    .nav-item {
      cursor: pointer;
      margin-top: 20px;
    }

    .nav-link {
      padding: 10px 0;
      margin-right: 30px;
      text-align: center;
    }

    #disabled-link {
      pointer-events: none;
      cursor:not-allowed;
    }

    .active {
      border-radius: 50px;
      background-color: #0d21e3;
      color: white;
      cursor: pointer;
      padding: 10px 30px;
    }
  }

  @media (max-width: 1200px) {
    .site-dashboard-links {
      padding-left: 0;
    }
  }

  @media (max-width: 1070px) {
    .site-dashboard-links {
      a {
        font-size: 13px;
      }
    }
  }

  .settings-section {
    background-color: #ffffffe0;
    padding: 20px 40px;
    margin-top: 30px;

    .send-to-developer {
      // justify-content: space-between;
      margin-top: 20px;
      padding-top: 50px;
      border-top: solid 0.3px #d8d8d8;

      p {
        font-size: 14px;
        padding: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    #tools-sections {
     padding: 0;
    }
  }

  #settings-sections-bulk {
    background: none;
    padding: 0 !important;

    .single-setting {
      background-color: #ffffffe0;

      .setting-content-wrapper {
        padding-left: 30px;
        padding-right: 30px;
      }

      .form-group {
        margin-bottom: 0 !important;
      }

      @media (max-width: 1340px) {
        .text-section {
          width: 100% !important;
        }
      }

      .text-section {
        width: 35%;

        img {
          margin-right: 10px;
        }

        p {
          font-size: 13px;
          padding: 0;
          margin: 0;
        }

        #site-tools {
          color: gray;
          font-size: 10px !important;
        }

        h3 {
          font-size: 22px;
        }
      }

      @media (max-width: 1340px) {
        #form-block-section {
          flex-direction: column !important;
          align-items: flex-start !important;

          .input-and-button-section {
            width: 100%;
            align-items: flex-end;
            margin-top: 20px;

            label {
              margin-left: 0;
            }
  
            input, select, .css-g1d714-ValueContainer {
              padding-left: 0 !important;
            }
          }
        }
      }

      .input-and-button-section {
        width: 50%;
        align-items: flex-end;

        .input-section {
          width: 60%;

          label {
            margin-left: 0.75rem;
            font-size: 13.5px;
            margin-bottom: 0;
            font-weight: bold;
          }

          input {
            border: none;
            border-bottom: solid 2px #d1d1d1;
            border-radius: 0;
            margin-right: 10px;
          }

          input:focus {
            border-bottom: solid 2px #0d21e3 !important;
          }

          select {
            border: none;
            border-bottom: solid 2px #d1d1d1;
            border-radius: 0;
          }
          select:focus {
            border-bottom: solid 2px #0d21e3 !important;
          }
        }

        .button-section {
          margin-left: 25px;
        }
        button {
          min-width: 170px !important;
        }
        
        img {
          width: 19px;
          cursor: pointer;
        }
      }

      @media (max-width: 1340px) {
        .description-section {
          width: 100% !important;
        }
      }

      .description-section {
        width: 60%;

        p {
          font-size: 13.5px;
        }

        #bold-text {
          font-weight: bold;
        }
      }
    }
  }
}

//.search-form{
//  input[type="checkbox"]{
//    display: block !important;
//    //height:22px;
//    //position:relative;
//    //top:-27px;
//    //right: 355px;
//    //  transform: translate(-22rem, -1.8rem);
//    height: 22px;
//    position: relative;
//    padding: 0;
//    top: -25px;
//    left: 0px;
//
//  }
//}

.display-name{
  display:flex;
  align-items: center;

  h2 {
    margin-right: 20px;
  }
}

#buttons-wrap {
  justify-content: space-between !important;
}


.display-text-field{
  padding-left:0 !important;

  input {
    border: none;
    background: transparent;
  }

  input:focus {
    background: transparent;
  }
}

.ignore-urls {
  padding-bottom: 15px;

  .dropdown-menu {
    float: none;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: calc(100% + 7px) !important;
    &:after {
      left: calc(50% + 10px);
    }
  }

  .url-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    
    tbody {
      
      tr {
        
        .url-table-heading {
          border-bottom: 1px solid #64646454;
          margin-bottom: 40px;
        }

        .url-table-body {
          padding: 30px 0 40px 0;

          span {
            font-size: 18px;
            cursor: pointer;
          }
        }

        #actions {
          text-align: center;
        }

        & + .url-table-col {
          border-top: 0px solid $gray2;
        }
      }
    }
  }
}

.filter-wrapper {
  select {
    border: none;
    border-bottom: solid 2px #d1d1d1;
    border-radius: 0;
    background: transparent;
    padding-left: 0;
    width: 240px;
  }
  select:focus {
    border-bottom: solid 2px #0d21e3 !important;
    background: none;
  }

  label {
    display: none;
  }
}

#ignore-cache-pop-up, .search-replace-line, .modal-body {
  h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  p {
    cursor: pointer;
  }

  input, select {
    border: none;
    border-bottom: solid 2px #d1d1d1;
    border-radius: 0;
    background: transparent;
  }

  input:focus, select:focus {
    border-bottom: solid 2px #0d21e3 !important;
    background: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body .top-navigation li {
    margin: 0 32px!important;
  }
}

@media (min-width: 1281px) and (max-width: 1599px) {
  body .top-navigation li {
    margin: 0 40px!important;
  }
}

