.btn-theme {
  font-size: 15px;
  color: $black;
  background: transparent;
  text-align: center;
  border:1px solid $gray;
  text-decoration: none;
  font-family: $circular-book;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 7px;
  height: 42px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(0.3s ease all);

  img {
    margin-left: 10px;
  }

  &.btn-black {
    background: $black1;
    border: 1px solid $black1;
    color: $white;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
  &.btn-red {
    background: $red1;
    border: 1px solid $red1;
    color: $white;
  }
  &.red-btn {
    background: $red !important;
    border-color: $red !important;
    color: $white !important;
    transition: 0.3s ease all;

    &:hover {
      opacity: 0.9;
    }
  }
  &.btn-yellow {
    background: $yellow;
    border: 1px solid $yellow;
    color: $white;
    &:hover {
      background: $black1;
      border-color: $black1;
      color: $white;
    }
  }
  &.btn-blue {
    background: $blue;
    border: 1px solid $blue;
    color: $white;
    &:not(.active):hover {
      background: $black1;
      border-color: $black1;
      color: $white;
    }
  }
  &.radius-btn-blue {
    border-radius: 50px;
    background: $blue;
    border: 1px solid $blue;
    color: $white;
    &:not(.active):hover {
      background: $black1;
      border-color: $black1;
      color: $white;
    }
  }
  &.btn-dark-blue {
    background: #0d21e3;
    border: 1px solid #0d21e3;
    color: $white;
    &:not(.active):hover {
      background: $black1;
      border-color: $black1;
      color: $white;
    }
  }
  &.login-btn {
    border-radius: 50px;
  }
  &.btn-outline {
    background: transparent;
    border-radius: 50px;
    color: $black;
    border: 1px solid $gray;
    &.blue-outline {
      color: $black1;
      border-color: $darkblue;

      &:not(.active):hover {
        background: $darkblue;
      }

      &.active {
        border: 1px solid $blue;
        background: $blue;
        color: $white;
      }
    }
    &:not(.btn-restore):not(.active):hover {
      background: $black1;
      border-color: $black1;
      color: $white;
    }
  }

  &.btn-outline-nohover {
    background: transparent;
    border-radius: 50px;
    color: $black;
    border: 1px solid $gray;
    &.blue-outline {
      color: $black1;
      border-color: $darkblue;

      &:not(.active):hover {
        //background: $darkblue;
        cursor: not-allowed;
      }

      &.active {
        border: 1px solid $blue;
        background: $blue;
        color: $white;
      }
    }
    &:not(.btn-restore):not(.active):hover {
      cursor: not-allowed
      //background: $black1;
      //border-color: $black1;
      //color: $white;
    }
  }

  &.btn-theme-disabled {
    color: #d1d1d1;
    font-size: 14px;
    line-height: 16px;
    font-family: $circular-bold;
    border: 1px solid $light-gray;
    border-radius: 50px;
    padding: 11px 20px;
    display: inline-block;
    text-align: center;
    background: $light-gray;
    &.btn-lg {
      min-width: 162px;
    }

    &.btn-big {
      padding: 13px 30px;
      font-size: 18px;
    }

  }

  &.btn-outline-disable {
    background: $light-gray;
    border-radius: 50px;
    color: #d1d1d1;
    border: 1px solid $light-gray;
  }

 &.btn-restore {
   &:after {
     display: none;
   }
 }
}

#backup-btn {
  border-radius: 20px;
}

#add-domain-btn {
  width: 100% !important;
  min-width: 0 !important;
}

#make-primary-btn {
  min-width: 40% !important;
}

#update-site-name-btn {
  border-radius: 7px;
  min-width: 100px;
  height: 32px;
}

.inline-buttons {
  a {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 767px) {
      display: block;
      margin: 0 auto 12px;
      width: 160px;
      &:last-child {
        margin-right: auto;
      }
    }
  }
}

.setting-content {
  .btn-theme {
    height: 39px;
    font-size: 14px;
    line-height: 39px;
    font-family: $circular-bold;
    min-width: 214px;
  }
}

a.open-admin {
  font-size: 14px;
  color: $black;
  font-family: $circular-bold;
  position: relative;
  &:after {
    content: '';
    height: 1px;
    position: absolute;
    width: calc(100% - 18px);
    bottom: 4px;
    left: 0;
    background: $black;
  }
  .icon-share {
    font-size: 9px;
    margin-left: 8px;
  }
}

//select
.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 42px !important;
  border-color: #ddd !important;
  box-shadow: none !important;
}

