.badge {
  font-size: 15px !important;
  min-width: 160px;
  padding: 10px !important;
  color: #fff;
  border-radius: 50px !important;
  font-family: "Circular-Book" !important;
  font-weight: 400 !important;
}
.badge.badge.status-active {
  background: #53a551;
}
.badge.badge.status-held {
  background: #3b3b3b;
}
.badge.badge.status-inactive {
  background: #cb444b;
}
.badge.badge.status-active-alt {
  background: #4757f6;
}
.create-remove-block .single-block {
  min-height: 242px;
}
.fixed-footer {
  text-align: right;
  padding: 10px 30px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 280px);
  background-color: #fcfcfd;
}
@media (max-width: 991px) {
  .fixed-footer {
    width: 100%;
    left: 0;
  }
}
body.menu-active .fixed-footer {
  left: 280px;
}
body.organization-dashboard .fixed-footer {
  width: calc(100% - 220px);
}
@media (max-width: 991px) {
  body.organization-dashboard .fixed-footer {
    width: 100%;
    left: 0;
  }
}
body.organization-dashboard.menu-active .fixed-footer {
  left: 220px;
}
/*pagination */
body #statistics-content-wrapper {
  padding-bottom: 30px !important;
}
@media (min-width: 992px) {
  .pr-60 {
    padding-right: 60px !important;
  }
}
ul.pagination {
  justify-content: flex-end;
  margin-bottom: 50px;
  padding-right: 50px;
}
ul.pagination li {
  font-size: 10px;
  margin: 0 2px;
}
ul.pagination li:not(.page-info) {
  background: #f7f7f7;
}
ul.pagination li.page-info {
  padding: 0 14px;
}
ul.pagination li a {
  display: block;
  padding: 0 14px;
}
/* box block */
.box-block {
  max-width: 1030px;
  padding: 0 35px;
}

#role-form-content {
  max-width: 100%;
  padding: 0 95px 0 35px;
}
@media (max-width: 991px) {
  .box-block {
    padding: 0 15px;
  }
}
.box-block .form-group {
  margin-bottom: 10px;
}
.box-block .form-group label {
  font-family: "Circular-Bold";
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  padding: 0 12px;
  font-weight: normal !important;
}

.box-block .form-group input {
  background-color: #fcfcfd;
  border: none;
  border-bottom: solid 2px #d1d1d1;
  border-radius: 0;
}

.box-block .form-group input:focus {
  border-bottom: solid 2px #0d21e3 !important;
}

.box-block .form-group textarea {
  background-color: #fcfcfd;
  border: none;
  border-bottom: solid 2px #d1d1d1;
  border-radius: 0;
  min-height: 45px !important;
  height: 100%;
}

.box-block .form-group textarea:focus {
  border-bottom: solid 2px #0d21e3 !important;
}

.features-block textarea {
  background-color: transparent;
  //border: none;
  border-bottom: solid 2px #d1d1d1;
  border-radius: 0;
  min-height: 45px !important;
  height: 100%;
  padding: 0 !important;
}

.features-block textarea:focus {
  border-bottom: solid 2px #0d21e3 !important;
}

.box-block .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../images/long-arrow-down-light.svg");
  background-repeat: no-repeat;
  background-position: 96% 50%;
  border: none;
  border-bottom: solid 2px #d1d1d1;
  border-radius: 0;
}
.box-block .form-group .form-control {
  height: 42px;
}
.box-block .form-group textarea.form-control {
  height: 96px;
}
@media (min-width: 768px) {
  .box-block .col-md-12 .form-group select {
    background-position: 98% 50%;
  }
}
.box-block .nav-pills {
  border: none;
  padding: 0;
}
.box-block .nav-pills li.nav-item {
  margin-right: 15px;
  max-width: 50%;
}
@media (max-width: 767px) {
  .box-block .nav-pills li.nav-item {
    margin-right: 8px;
  }
}
.box-block .nav-pills li.nav-item a.nav-link {
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #707070;
  border-radius: 0;
  padding: 15px 10px;
  font-family: "Circular-Bold";
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-block .nav-pills li.nav-item a#has-error-box {
  border: 1px solid red;
}

.box-block .nav-pills li.nav-item a#has-error-box:hover {
  border: 1px solid #707070;
  background-color: #0d21e3 !important;
}
@media (max-width: 767px) {
  .box-block .nav-pills li.nav-item a.nav-link {
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
  }
}
.box-block .nav-pills li.nav-item a.nav-link.active, .box-block .nav-pills li.nav-item a.nav-link:hover {
  color: #fff;
  background: #4757f6;
  border-color: #4757f6;
  top: 0;
}
.box-block .nav-pills li.nav-item a.nav-link.active:after, .box-block .nav-pills li.nav-item a.nav-link:hover:after {
  display: none;
}
.box-block .nav-pills li.nav-item:last-child {
  margin-right: 0;
}
.box-block .padded-block {
  padding: 30px 0;

  #role-form-inputs {
    display: flex;
    input {
      width: 90%;
      margin-right: 200px;
    }
  }
}
.box-block .wp-password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.box-block .wp-password-input-wrapper input {
  padding-right: 45px;
}
.box-block .wp-password-input-wrapper a {
  padding: 0 0 0 10px;
}
.box-block .wp-password-input-wrapper .copy {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translate(2rem, -0.73rem);
  z-index: 1;
}
.box-block hr {
  margin: 25px 0;
}
@media (min-width: 768px) {
  .role-check-block .row div[class*="col-"]:first-child {
    padding-right: 5%;
  }
}
.role-check-block .row div[class*="col-"]:last-child {
  border-left: 0;
  margin-top: 35px;
}
@media (min-width: 768px) {
  .role-check-block .row div[class*="col-"]:last-child {
    padding-left: 5%;
    border-left: 1px solid rgba(112, 112, 112, 0.12);
    margin-top: 0;
  }
}
.role-check-block .button-wrapper {
  margin-top: 100px;
}
@media (max-width: 767px) {
  .role-check-block .button-wrapper {
    margin-top: 40px;
  }
}
.single-role + .single-role {
  margin-top: 35px;
}
.single-role .role-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-role .role-heading p {
  font-family: "Circular-Bold";
  margin-bottom: 0;
}
.single-role .role-heading .badge {
  min-width: auto !important;
  width: 99px;
  background: #acacac;
  border-radius: 0 !important;
  font-size: 14px !important;
  text-transform: uppercase;
}
.single-role .role-heading .badge.status-full {
  background: #4757f6;
}
.single-role ul {
  list-style: none;
  padding-left: 30px;
  margin-top: 6px;
  position: relative;
  margin-bottom: 0;
}
.single-role ul:before {
  content: '';
  position: absolute;
  width: 1px;
  height: calc(100% - 6px);
  background: #4757f6;
  top: -6px;
  left: 15px;
}
.single-role ul li {
  position: relative;
}
.single-role ul li:before {
  content: '';
  position: absolute;
  width: 12px;
  height: 1px;
  background: #4757f6;
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
}
.single-role ul li .custom-switch {
  padding-left: 0;
}
.single-role ul li .custom-switch input.custom-control-input + label.custom-control-label {
  width: 100%;
  margin-top: 0;
  padding-right: 50px;
  padding-left: 5px;
  top: 2px;
}
.single-role ul li .custom-switch input.custom-control-input + label.custom-control-label:before {
  left: auto;
  top: 0;
  right: 0;
  width: 45px;
  height: 23px;
  border-radius: 50px;
  background: #bababa;
  border-color: #bababa;
}
.single-role ul li .custom-switch input.custom-control-input + label.custom-control-label:after {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #fff;
  left: auto;
  right: 23px;
  top: 1px;
}
.single-role ul li .custom-switch input.custom-control-input:checked + label.custom-control-label {
  font-family: "Circular-Book" !important;
}
.single-role ul li .custom-switch input.custom-control-input:checked + label.custom-control-label:before {
  background: #4757f6;
  border-color: #4757f6;
}
.single-role ul li .custom-switch input.custom-control-input:checked + label.custom-control-label:after {
  right: 13px;
}
.single-role ul li + li {
  margin-top: 10px;
}

/* new scss for card role */
.role-check-block .card-columns {
  -webkit-column-count: 1;
  column-count: 1;
}
.role-check-block .card-columns .card {
  border: none;
  margin-bottom: 30px;
  background-color: #fcfcfd;
}

.single-role .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-role .form-group label {
  margin-bottom: 0;
  font-family: "Circular-Book";
  padding: 0 20px 0 5px;
}

.single-role ul:before {
  top: -8px;
}


@media (min-width:768px) {
  .role-check-block .card-columns {
    position:relative;
    margin: 0 -30px;
    -webkit-column-count: 3;
    column-count: 3;
  }
  .role-check-block .card-columns:before {
    content:'';
    position:absolute;
    top: 0;
    left: 33.5%;
    width: 1px;
    height: 100%;
    background: rgba(112,112,112,.12);
  }

  .role-check-block .card-columns:after {
    content:'';
    position:absolute;
    top: 0;
    left: 67%;
    width: 1px;
    height: 100%;
    background: rgba(112,112,112,.12);
  }
  .role-check-block .card-columns .card {
    padding: 0 30px
  }
}

.form-group.checkbox-wrapper {
  position:relative;
}



.domain-form input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
}

.has-error .css-2b097c-container {
    //border: 1px solid $red;
    border: none;
  }
.has-error .css-yk16xz-control {
  border:1px solid red !important;
}

.css-yk16xz-control {
  background: none !important;
  border: none !important;
  border-bottom: solid 2px #d1d1d1 !important;
  border-radius: 0 !important;
}

.css-1pahdxg-control {
  background: none !important;
  border: none !important;
  border-bottom: solid 2px #d1d1d1 !important;
  border-radius: 0 !important;
}

.css-1okebmr-indicatorSeparator {
  background: none !important;
}


.single-role ul {
  margin-top: 12px;
}

.single-role ul:before {
  height: calc(100% + 2px);
  top: -14px;
}

.fixed-footer {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 30px;
  ul.pagination {
    margin-bottom: 20px;
    margin-left: auto;
    @media (max-width: 991px) {
      padding-right: 20px;
    }
  }
  .footer-text-block {
    margin-bottom: 20px;
  }
  @media (max-width: 991px) {
    padding: 10px 15px;
  }
}