body header {
  background: #000 !important;
}

body .top-navigation {
  height: 56px;
  background: black;
  // margin-left: 219px;
  // width: calc(100% - 219px);
  display: flex;
}

@media (max-width: 1280px) {
  body .top-navigation {
    margin-left: 219px;
    width: calc(100% - 219px);

    li {
      margin: 0 40px !important;
    }
  }
}

@media (max-width: 991px) {
  body .top-navigation {
    display: none;
  }
}

body .sidebar-wrapper .sidebar .left-sidebar {
  background: #0e1226 !important;
}

@media (max-width: 991px) {
  body .sidebar-wrapper .sidebar .left-sidebar .heading-title {
    margin-top: 85px;
  }
}

body .sidebar-wrapper .sidebar .left-sidebar .heading-title p {
  color: #fff;
  font-size: 18px;
  transform: rotate(90deg);
  font-family: "Circular-Bold";
  white-space: nowrap;
  margin-bottom: 0;
  text-align: center;
  margin-top: 35px;
}

body .sidebar-wrapper .sidebar .right-sidebar .title-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 14px 30px;
}

body .sidebar-wrapper .sidebar .right-sidebar .title-block p {
  margin-bottom: 0;
  font-size: 18px;
  font-family: "Circular-Bold";
}

//body .sidebar-wrapper .sidebar .right-sidebar ul li:before {
//  content: '' !important;
//  width: 22px;
//  height: 22px;
//  top: 0;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.organization-link:before {
//  background: url( "../../../assets/images/organization.svg") no-repeat;
//}
//
//body .sidebar-wrapper .sidebar .right-sidebar ul li.data-link:before {
//  background: url("../../../assets/images/server-light.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.load-link:before {
//  background: url("../../../assets/images/chart-pie-light.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.user-link:before {
//  background: url("../../../assets/images/user-light.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.roles-link:before {
//  background: url("../../../assets/images/user-tag-light.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.sites-link:before {
//  background: url("../../../assets/images/site.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.users-link:before {
//  background: url("../../../assets/images/user-light.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.activity-link:before {
//  background: url("../../../assets/images/log.svg") no-repeat;
//}
//body .sidebar-wrapper .sidebar .right-sidebar ul li.analytics-link:before {
//  background: url("../../../assets/images/chart-pie-light.svg") no-repeat;
//}
body.login-pages .sidebar-wrapper {
  max-width: 64px;
  z-index: 9;
}

@media (max-width: 991px) {
  body.login-pages .sidebar-wrapper {
    left: 0;
  }
}

// body.login-pages .dashboard-login {
//   padding-left: 64px;
// }
body.login-pages .dashboard-login .logo-block {
  background-image: url("../../images/login-background.svg");
  position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.heading-block .title-block h2 span {
  font-family: "Circular-Book";
}

.single-setting .setting-title h2 {
  background: #4757f6;
}

//.single-setting .setting-title h2.live:after {
//  position: absolute;
//  content: '';
//  background: #1ae000;
//  width: 30px;
//  top: 0;
//  right: 0;
//  height: 100%;
//}
.badge {
  font-size: 15px;
  min-width: 160px;
  padding: 10px;
  color: #fff;
  border-radius: 50px;
  font-family: "Circular-Book";
  font-weight: 400;
  margin-left: 10px;
  opacity: 0.37;
}

.badge.status-active {
  background: #53a551;
}

.badge.status-held {
  background: #3b3b3b;
}

.badge.status-cancelled {
  background: #cb444b;
}

/* organization page */
body.organization-dashboard .sidebar-wrapper {
  max-width: 220px;
  width: 220px;
  background: white;

  .sidebar {
    height: 100%;

    .no-gutters {
      height: 100%;
    }
  }
}

@media (max-width: 991px) {
  body.organization-dashboard .sidebar-wrapper {
    left: -220px;
    width: 220px;
    height: 100%;
  }
}

body.organization-dashboard .sidebar-wrapper .sidebar .right-sidebar {
  max-width: 241px;
  width: 241px;

  .title-block {
    padding: 17px 30px;

    a {
      display: flex;
      justify-content: center;
      margin-top: 0 !important;
    }

    img {
      width: 85%;
    }
  }
}

body.organization-dashboard .sidebar-wrapper .sidebar .right-sidebar ul {
  margin-top: 25px;
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .left-menu {
  width: 55px;
  max-width: 55px;
  text-align: center;
  height: calc(100vh - 60px);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .left-menu
  ul {
  padding: 0;
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .left-menu
  ul
  li {
  padding: 0;
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .right-menu {
  width: calc(100% - 55px);
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .right-menu
  ul {
  padding: 0 0 0 30px;
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .right-menu
  ul
  li
  + li {
  margin-top: 15px;
}

body.organization-dashboard
  .sidebar-wrapper
  .sidebar
  .right-sidebar
  .right-menu
  ul
  li:before {
  top: 2px;
}

body.organization-dashboard .sidebar-wrapper.active {
  left: 0;
}

@media (min-width: 992px) {
  body.organization-dashboard .content-area-wrapper {
    margin-left: 220px;
    width: calc(100% - 220px);
    // background-color: rgba(244,244,245,.23);
    padding-top: 26px;
  }
}

body.organization-dashboard .content-area-wrapper .under-development {
  display: flex;
  position: fixed;
  width: calc(100% - 220px);
  height: 100%;
  justify-content: center;
  align-items: center;
}

body.organization-dashboard .content-area-wrapper .page-content {
  display: flex;
  position: fixed;
  width: calc(100% - 220px);
  height: 100%;
}

body.organization-dashboard
  .content-area-wrapper
  .page-content
  .content-section {
  width: 100%;
  overflow: scroll;
  padding-bottom: 100px;
}

body.organization-dashboard
  .content-area-wrapper
  .page-content
  .organizations-sidebar {
  width: 150px;
}

body.organization-dashboard
  .content-area-wrapper
  .page-content
  #inside-organization-pagination {
  width: calc(100% - 320px);
}

body.organization-dashboard.menu-active #root {
  left: 220px;
}

body.organization-dashboard.menu-active:after {
  height: 1000%;
  left: 220px;
  width: calc(100% - 220px);
}

body .sidebar-wrapper .sidebar .left-sidebar ul.lower-menu li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-buttons button + a {
  margin-left: 15px;
}

.item-disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: none;
}

//.site-data-block {
//  margin: 0 30px;
//  background: rgba(245, 245, 245, 0.5);
//  padding: 30px;
//}
//.site-data-block .single-site-data {
//  background: #fff;
//  border-radius: 4px;
//  border-left: 10px solid #4757f6;
//  padding: 30px;
//  margin-bottom: 30px;
//}
//.site-data-block .single-site-data .data-number {
//  margin-bottom: 24px;
//}
//.site-data-block .single-site-data .data-number h2 {
//  font-size: 26px;
//  line-height: 34px;
//  font-family: 'Circular-Book';
//  color: #454458;
//}
//.site-data-block .single-site-data .site-output {
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: space-between;
//  align-items: flex-end;
//}
//.site-data-block .single-site-data .site-output .data-title p {
//  font-size: 18px;
//  line-height: 24px;
//  color: #6c7293;
//}
//.site-data-block .single-site-data .site-output .output-result p {
//  font-size: 16px;
//  line-height: 21px;
//}
//.site-data-block .single-site-data .site-output .output-result p.increase {
//  color: #23b899;
//}
//.site-data-block .single-site-data .site-output .output-result p.decrease {
//  color: #f50808;
//}
//.site-data-block .single-site-data .site-output .output-result p span[class*="icon-"] {
//  margin-left: 6px;
//}
.no-staging {
  margin: 0 30px;
  padding: 10% 0;
}

@media (max-width: 991px) {
  .no-staging .text-block {
    margin-bottom: 30px;
  }
}

.no-staging h2 {
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 30px;
}

.no-staging p {
  font-size: 18px;
  line-height: 26px;
}

.single-site-data {
  height: calc(100% - 30px);
}

.table {
  margin-bottom: 90px;
}

.fit-content {
  max-width: fit-content;
}

// .single-feature .btn-wrapper {    width: 100%;
// }

.table #backup-table-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 30px;

  th:last-child {
    text-align: center;
  }
}

.table-block .table tbody tr td {
  position: relative;
}

.table-block .table tbody tr td .position-absolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-role .react-switch-bg {
  background: #bababa !important;
  width: 45px !important;
  height: 23px !important;
}

.single-role .react-switch-handle {
  width: 21px !important;
  height: 21px !important;
}

.single-role .active .react-switch-handle {
  transform: translateX(23px) !important;
  background: #fff !important;
}

.single-role .active .react-switch-bg {
  background: #4757f6 !important;
}

.single-role .role-heading .badge {
  border: none;
  padding: 10px !important;
  width: auto;
  min-width: 99px !important;
}

.single-role ul:before {
  height: calc(100% - 3px);
}

.single-setting
  .setting-content
  .setting-content-wrapper
  .content-info.content-info-wrapper
  span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.site-data-block .single-site-data .site-output .data-title {
  flex: 0 0 60%;
  max-width: 60%;
}

.site-data-block .single-site-data .site-output .output-result {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: right;
}

.site-data-block .single-site-data {
  padding: 20px;
}

.site-data-block .single-site-data .site-output {
  align-items: flex-start;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .site-data-block div[class*="col-"] {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.icon-check {
  color: green !important;
}

@media (min-width: 1921px) {
  .heading-block .title-block {
    max-width: fit-content;
  }
}

.div-site-status + .heading-block {
  margin-top: 55px;
}

.imgPreview {
  border-radius: 50%;
  padding: 5px;
  width: 150px;
  height: 50%;

  img {
    border-radius: 50%;
  }
}

.file-upload {
  display: none;
}

.image-upload input[type="file"] {
  border: none;
  box-shadow: none;
  margin-bottom: 15px;
}

.imgPreview img {
  width: 120px;
  height: 120px;
}

.lower-menu .sidebar-avatar {
  height: 30px;
  width: 30px;
}

.live-text {
  background-color: #4bd24b;
  width: 40px;
  text-align: center;
  color: white !important;
}

.search-replace-line {
  border-top: 1px solid transparentize($black, 0.94);
  padding-top: 10px;
}

.search-header {
  font-size: 20px;
}

.delete-site-header {
  font-size: 20px;
}

.delete-site-line {
  border-top: 1px solid transparentize($black, 0.94);
  padding-top: 10px;
}

.thick-text {
  font-weight: bold;
}

//additional column for 5

.col-xxl-5 {
  @media (min-width: 1441px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

//dashboard restyle
//.block-column-site-data {
//  &:last-child {
//    .single-site-data {
//      border-right: 0;
//    }
//  }
//
//  &:nth-child(3n) {
//    .single-site-data {
//      border-right: 0;
//    }
//  }
//}
//.block-column-site-data {
//  &:last-child {
//    .single-site-data {
//      border-right: 0;
//      border-bottom: 0;
//    }
//  }
//  &:nth-child(3n) {
//    .single-site-data {
//      @media (min-width: 1200px)  {
//        border-right: 0;
//      }
//    }
//  }
//  &:nth-child(2n) {
//    .single-site-data {
//      @media (min-width: 768px) and (max-width: 1199px) {
//        border-right: 0;
//      }
//    }
//  }
//  .single-site-data {
//    @media (max-width: 767px) {
//      border-right: 0 !important;
//      border-bottom: 1px solid #d6d6d6;
//    }
//  }
//}
.block-column-site-data {
  &:last-child {
    .single-site-data {
      border-right: 0;
      border-bottom: 0;
    }
  }

  &:nth-child(3n) {
    .single-site-data {
      @media (min-width: 1200px) and (max-width: 1599px) {
        border-right: 0;
      }
    }
  }

  &:nth-child(2n) {
    .single-site-data {
      @media (min-width: 768px) and (max-width: 1199px) {
        border-right: 0;
      }
    }
  }

  .single-site-data {
    @media (max-width: 767px) {
      border-right: 0;
      border-bottom: 1px solid #d6d6d6;
    }
  }
}

.dashboard-content {
  display: flex;
}

.site-data-block {
  padding: 25px 20px 0 35px;
  width: 30%;

  .title-block {
    margin-bottom: 40px;
  }

  .single-site-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 28px 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray3;

    .data-title {
      flex-grow: 1;

      h2 {
        font: {
          size: 19px;
          family: $circular-book;
        }
        line-height: 24px;
        color: $black;
        margin-bottom: 28px;
      }
    }

    .site-output {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      .data-number {
        p {
          font: {
            size: 32px;
            family: $circular-bold;
          }
        }
      }

      .output-result {
        p {
          font: {
            size: 19px;
            family: $circular-book;
          }

          &.increase {
            color: $green3;
          }

          &.decrease {
            color: $red2;
          }

          span {
            display: none;
          }
        }
      }
    }
  }
}

.statistics-data-block {
  padding: 25px 35px 0px 35px;
  width: 100%;

  .title-block {
    margin-bottom: 40px;
  }

  .statistics-rowing {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    #statistics-row {
      width: 31%;

      .single-site-data {
        display: flex;
        flex-direction: column;
        padding: 0px 28px 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray3;

        .data-and-center {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;

          p {
            margin: 0;
          }

          p:last-child {
            font-weight: bold;
          }
        }

        .data-title {
          flex-grow: 1;

          h2 {
            font: {
              size: 15px;
              family: $circular-book;
            }
            line-height: 24px;
            color: $black;
            margin-bottom: 28px;
          }
        }

        .site-output {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;

          .data-number {
            p {
              font: {
                size: 32px;
                family: $circular-bold;
              }
            }
          }

          .output-result {
            p {
              font: {
                size: 19px;
                family: $circular-book;
              }

              &.increase {
                color: $green3;
              }

              &.decrease {
                color: $red2;
              }

              span {
                display: none;
              }
            }
          }
        }
      }

      #data-and-center-data {
        justify-content: flex-end;
      }
    }
  }
}

//.table-striped{
//  margin-bottom: 60px !important;
//}
.ssl-form {
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid transparentize($gray, 0.61);
    border-radius: 0;
    padding-right: 60px;
    background: none;

    &::placeholder {
      color: transparentize($gray, 0.61);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: transparentize($gray, 0.61);
    }

    &::-ms-input-placeholder {
      color: transparentize($gray, 0.61);
    }
  }
}

.ignore-urls {
  .url-table {
    tbody {
      tr {
        th#actions {
          width: 214px;
        }

        td#actions {
          .checkbox-wrapper {
            margin-bottom: 0;

            input[type="checkbox"] {
              width: 15px;
              height: 15px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.error-msg {
  color: red;
}

.coming-soon {
  //text-align: right;
  //text-align:center;
  color: red;
}

table.table-with-action-dropdown {
  thead {
    .action-th {
      width: 210px;
    }
  }

  tbody {
    tr {
      td.action-td {
        vertical-align: top;
        width: 210px;

        .collapse-link {
          cursor: pointer;
        }

        .collapse-content {
          height: 0;
          opacity: 0;
          visibility: hidden;
          margin-top: 6px;
          transition: 0.2s all ease-in-out;
        }

        &:hover {
          .collapse-content {
            height: auto;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.loading-domain {
  text-align: right;
  padding-right: 110px !important;
}

.delete-site {
  margin-bottom: 15px;

  .delete-site-name {
    background: transparentize($gray1, 0.77);
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0 20px 0 22px;
  }

  .delete-site-form {
    background: transparentize($gray1, 0.77);
    height: 110px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 22px;
  }
}

.input-div {
  position: relative;

  span.icon-checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

.ssl-form {
  textarea {
    border: none !important;
    border-bottom: 1px solid #e6e6e6 !important;
  }

  .form-group {
    textarea {
      height: 200px;
    }
  }
}

.ssl-view-form {
  textarea {
    border: none !important;
    border-bottom: 1px solid #e6e6e6 !important;
  }
}

.onclick {
  cursor: pointer;
}

.popup-checkbox input[type="checkbox"] {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  //left: 16px;
}

//.redirect-form {
//  input {
//    width: 100%;
//    border: 1px solid #E6E6E6  !important;
//    //border-bottom: 1px solid transparentize($gray, .61);
//    border-radius: 0;
//    padding-right: 60px;
//
//  }
//  textarea {
//    border: 3px solid #E6E6E6 !important;
//  }
//  .has-error{
//    input {
//      border-color: red !important;
//    }
//  }
//  .form-group {
//    input:focus {
//      border: solid 2px #0d21e3 !important;
//    }
//  }
//
//}

.popup-checkbox {
  .has-error {
    color: red;
  }
}

.tab-domain-button {
  .btn-theme {
    border-radius: 50px;
  }
}

.heading-block {
  .select-block {
    max-width: 450px;
    margin-left: auto;
  }
}

//.custom-popup {
.redirect-form {
  .form-group {
    input {
      border: 1px solid #e6e6e6;
      padding: 0 10px;
    }
  }

  .has-error input,
  .has-error select,
  .has-error textarea {
    border: 1px solid red !important;
  }

  .form-check {
    .has-error {
      color: red;
    }
  }
}

//}

.enable2fa-form {
  .form-group {
    input {
      border: 1px solid #e6e6e6;
      padding: 0 10px;
    }

    .has-error input,
    .has-error select,
    .has-error textarea {
      border: 1px solid red !important;
    }
  }
}

.enable2fa {
  text-align: center;
}

.verify-authy-popup {
  max-width: 580px;
  text-align: center;
}

.enable-2fa-popup {
  max-width: 580px;
  //text-align: center;
}

//.multiple-value-text-input .multiple-value-text-input-item{
//  background: #ccc;
//}

/*
.domain-form{
  .error {
    span {
      background: red !important;
    }
  }
}*/

.password-reset-form {
  .form-group {
    input {
      border: none;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 10px;
      background-color: #fcfcfd;
      border-radius: 0;
    }

    .has-error input {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid red !important;
    }
  }
}

.password-reset-success-message {
  h3 {
    text-align: right;
    //margin-left:50px;
    color: green;
  }
}

.long-button {
  width: 100%;
}

.fortnox-title {
  display: flex;

  .fortnox-title-error {
    align-items: center;
    padding-left: 10px;
    color: red;
    padding-top: 5px;
  }
}

.heading-checkbox-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .new-client-title-error {
    color: red;
    padding-top: 10px;
  }

  input {
    height: auto !important;
  }

  label {
    padding-left: 20px !important;
  }

  .title-checkbox {
    margin-top: 10px;
  }
}

.heading-block-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 35px;
  padding-top: 22px;
}

.payment-due-title {
  margin-top: 20px;
  width: auto;
  position: relative;
  @media (min-width: 1500px) and (max-width: 1900px) {
    padding: 0 0px 0 300px;
  }

  .due-amount {
    color: red;
  }
}

.site-output {
  .amount-number {
    .amount {
      color: green;
      font-size: 24px;
    }
  }
}

.fortnox-heading {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.fortnox-form-heading {
  padding-left: 0px !important;
  margin-top: 25px;
}

.payment-table {
  margin-bottom: 130px;
}

.description-price-includes {
  margin-left: 176px;
}

.backup-footer {
  ul.pagination {
    padding-right: 130px;
  }
}

.backup-status-table {
  th {
    text-align: center;
  }

  th:last-child {
    text-align: center !important;
  }

  td {
    text-align: center !important;
  }
}

.backup-status-header {
  margin-bottom: 30px;
  margin-top: 40px;
}

.td-with-array {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  position: relative;

  p {
    margin-left: 2px;
    padding: 0 !important;
  }

  button {
    .btn-theme {
      width: 50% !important;
    }
  }

  a {
    color: white;
  }
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  display: flex;
  background: #0d21e3;
  border-radius: 3px 0 0 3px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
}
.tag-danger {
  background: red;
  color: white !important;
}
.tag-danger-green {
  background: #80ee80;
  color: white !important;
}
.tag-danger-yellow {
  background: #e0e05d;
  color: white !important;
}

.tag:hover {
  background: #1a293b;
  color: #fff !important;
}

.tag::before {
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  top: 10px;
}

.bugs-status {
  display: flex;
  background: #0d21e3;
  border-radius: 3px 0 0 3px;
  height: 35px;
  line-height: 35px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  color: white !important;
  justify-content: center;
}

.bugs-completed {
  display: flex;
  background: #09c413;
  border-radius: 3px 0 0 3px;
  height: 35px;
  line-height: 35px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  color: white !important;
  justify-content: center;
}

.tag::after {
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
  p {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }
}

.box-block-alt .form-group input {
  background-color: #fcfcfd;
  border: none;
  border-bottom: solid 2px #d1d1d1;
  border-radius: 0;
}
.box-block-alt .form-group select {
  border: none;
  border-bottom: solid 2px #d1d1d1;
  border-radius: 0;
}

.drag-drop {
  //font-family: sans-serif;
  align-items: center;
  flex-direction: column;
  width: 100%;
  label {
    //width: 900px;
    box-sizing: border-box;
    width: 100%;
    display: block;
    max-width: none;
  }
  .hyiOnG {
    //max-width: 755px;
    padding: 12px 16px 10px 8px !important;
    border: dashed 2px #999 !important;
  }
}
.drag-drop-icon {
  text-align: center;
}
.fa-upload:before {
  margin-right: 15px;
}
/*.drag-drop{
  width:100%;
  label{
    width:100%;
    display:block;
    max-width:none;
    //height;
    //bordercolor
  }
}*/
.ql-editor {
  min-height: 18em;
}

.ql-color {
  border-color: red;
}

.main-menu + .main-menu {
  margin-top: 40px !important;
  margin-bottom: 40px;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.big-span-blue {
  color: blue !important;
}

.description-error {
  .ql-toolbar {
    border-color: red !important;
  }
  .ql-container.ql-snow {
    border: 1px solid red;
  }
}

.btn-theme.btn-text {
  color: #0d21e3;
  border: 0;
  padding: 0;
  min-width: auto;
  position: relative;
}

.btn-text-wrapper {
  margin-bottom: 30px;
  padding: 4px 0;
}

.btn-theme.btn-text:before {
  content: "";
  position: absolute;
  background: transparent;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 8px;
  transition: 0.3s all ease-in-out;
}

.btn-theme.btn-text:hover:before {
  background: #0d21e3;
  width: 100%;
}

button.btn-theme.btn-text:after {
  content: "\e109";
  font-family: icomoon !important;
  margin-left: 7px;
}

button.btn-theme.btn-text.all-copied:after {
  content: "\e104";
}

@media (max-width: 1440px) {
  .wrap-1440 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
