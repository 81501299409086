//Row Gutters
$grid-gutter-width: 15px;

// Theme ../font family variables




$circular-bold : "Circular-Bold";
$circular-book : "Circular-Book";
$circular-medium : "Circular-Medium";
$courier: "Courier-New-Regular";


// Theme ../Color variables

$theme-color  : #1E1E1E;

$white      : #ffffff;

$black      : #000000;

$black1      : #1A293B;

$red        : #C60000;

$red1        : #DB2200;

$red2        : #E30D0D;

$gray        : #c4bebe;

$light-gray : #f1f1f1;

$gray1        : #6F6F6F;

$light-gray   : #f1f1f1;

$gray2        : #D7D7D7;

$gray3        : #D6D6D6;

$green       : #3DB100;

$green1       : #07B200;

$green2       : #1AE000;

$green3       : #13A741;

$yellow      : #FFB100;

$yellow1      : #FFD800;

$yellow2      : #FFD10F;

$purple     : #8A02FF;

$blue       : #4757F6;

$darkblue      : #051439;






$light-gray  : #F0F0F0;




$dark-light-gray        : #F4F4F4;

// Theme ../Font size variables

$size1  : 1px;
$size2  : 2px;
$size3  : 3px;
$size4  : 4px;
$size5  : 5px;
$size10 : 10px;
$size12 : 12px;
$size14 : 14px;
$size16 : 16px;
$size18 : 18px;
$size20 : 20px;
$size22 : 22px;
$size24 : 24px;
$size26 : 26px;
$size28 : 28px;
$size30 : 30px;
$size40 : 40px;
$size50 : 50px;
$size60 : 60px;
