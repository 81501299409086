html {
    background-color: #fcfcfc;
}

.Download-App {
    display: none;
}

@media (max-width: 991px) {
    html {
        background-color: white;
    }

    .Main-App {
        display: none;
    }

    .Download-App {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-image: url('../images/ipad.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (max-width: 414px) {
    .Download-App {
        background-image: url('../images/mobile.svg');
        background-position: right;
    }

    .Download-App h3 {
        font-size: 25px;
    }
}
