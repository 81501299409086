body {
  header {
    background: $black1;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 10;
    left: 0;
    padding: 2px 25px;
    box-shadow: 0 1px 5px rgba(0,0,0,.2);
    @media (min-width: 992px) {
      display: none;
    }
    @media (max-width: 991px) {
      padding: 10px 15px;
    }


    .navbar {
      padding: 0;
    }
    .brandLogo {
      span {
        font-size: 26px;
        color: $white;
      }
    }
    .navbar-toggle-btn {
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      right: 0;
      display: inline-block;
      transition: all 200ms ease;

      span {
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: $white;
        position: absolute;
        transition: all 200ms ease;
        left: 0;
        top: calc(50% - 1.5px);
        &:first-child {
          transform-origin: center;
          animation-duration: 600ms;
          animation-fill-mode: forwards;
          animation-name: rotate1;
          transform: translateY(-300%);
        }

        &:last-child {
          transform-origin: center;
          animation-duration: 600ms;
          animation-fill-mode: forwards;
          animation-name: rotate2;
          transform: translateY(300%);
        }
      }
      &.open{
        span {
          &:first-child {
            transform: translateY(0);
            animation-name: rotate-open-1;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            transform: translateY(0);
            animation-name: rotate-open-2;
          }
        }
      }
    }
  }
}





@keyframes rotate1 {
  0% {
    transform: translateY(0) rotate(45deg);

  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes rotate2 {
  0% {
    transform: translateY(0) rotate(-45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(300%);
  }
}

@keyframes rotate-open-1 {
  0% {
    transform: translateY(-300%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(-45deg);
  }
}

@keyframes rotate-open-2 {
  0% {
    transform: translateY(300%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}
/** here start custome css header **/
body header .navbar {
  padding: 10px 0;
}
.heading-block .form-group {
  margin-bottom: 0;
}
.heading-block {
  flex-wrap: wrap;
}
.heading-block .title-block {
  width: auto;
  flex-grow: 1;
  max-width: 100%;
}
.heading-block .title-block span + span:before {
  content: '/';
  margin: 0 8px;
  //font-family: "Circular-Book";
}
.heading-block .search-bar {
  width: auto;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.select-block {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 20px;
}
#filter-datacenter {
  flex-grow: 0.45;
}

.div-site-status{
    text-align: center;
    color: #ffffff;
    background: #2f43ff;
    padding: 13px 0;
    margin-top: -10px;
    width: 100vw;
    left: -220px;
    position: absolute;
    margin-top: 0px;
}
.div-site-status-inside-form{
  //padding: 5px;
  text-align: center;
  color: $white;
  background: #4463ef;
  margin-top: -30px;
}

@media (max-width: 1200px) {
  .div-site-status {
    left: 0;
    width: 100%;
    }
  }

@media (max-width: 1199px) {
  .heading-block .title-block {
    flex: 0 0 100%;
  }
  .heading-block .title-block h2 .site-name {
    margin-left: 20px;
    display: inline-block;
  }
  .heading-block .search-bar {
    padding: 0 20px 0 0;
  }
}
@media (max-width: 767px) {
  .select-block {
    padding-right: 0;
    margin-top: 20px;
  }
  .heading-block .search-bar {
    padding: 0;
  }
}