.statistics-content {
  #kepler-map {
    width: 96%;
    margin: auto;
    height: 49vh;
  }  
}

#kepler-map {
  width: 70%;
}

.gm-style-iw {
  &.gm-style-iw-c {
    padding: 0 !important;

    .gm-style-iw-d {
      overflow: hidden !important;
      max-height: none !important;
      min-width: 140px !important;

      + button{
        display: none !important;
      }
    }
  }
}
.map-infowindow  {
  .lists-wrapper {
    padding: 20px 0 20px 0;
  }

  .lists {
    padding: 0 20px 0 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 110px;
  }

  h3 {
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    color: #000000;

    & + h3 {
      margin-top: 15px;
    }
  }

  .total-sites {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px 20px 20px;

    p{
      font-size: 32px;
      font-weight: bold;
      color: #000000;
      margin: 0;
    }

    span{
      font-size: 19px;
      color: #13A741;
      font-weight: normal;
    }
  }
}