.dropdown-alt {
  .dropdown-toggle {
    display: block;
    cursor: pointer;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    text-align: center;

    a {
      display: block;
      padding: 15px 20px;
      line-height: normal;
      
      &:hover {
        background: $dark-light-gray;
        color: #000;
      }

      &.link-delete {
        color: $red;
      }
    }

    &.show {
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: 20px !important;
    }

    &::after {
      left: -webkit-calc(50% + 10px);
      left: calc(50% + 10px);
    }
  }
}